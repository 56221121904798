<template>
  <div class="ui-targets-list t_margin_small">
    <div
      v-for="(item, index) in items"
      :key="index"
      class="ui-targets-list-item"
    >
      <img v-scrollanimate="'bottom'" src="/aboutArmor/target.svg" alt="" />
      <span v-scrollanimate="'bottom'" class="text_small">{{ $t(item) }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "uiTargetsList",
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../vars";
.ui-targets-list {
  display: flex;
  flex-direction: column;
  .ui-targets-list-item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 20px;
    img {
      width: 20px;
    }
    span {
      margin-left: 10px;
      display: block;
    }
  }
}
@media (min-width: $large_w) {
  .ui-targets-list .ui-targets-list-item img {
    width: 31px;
  }
}
</style>
